import { useMediaQuery } from '@mui/material';
import getConfig from 'next/config';
import { v4 as uuidv4 } from 'uuid';

import { BreadcrumbsElement } from '@tgg/common-types';
import { axiosInstance } from '@tgg/micro-services/axios';
import { CmsClient } from '@tgg/micro-services/cms-client';
import {
    BlogCategories,
    BlogPostsList,
    Breadcrumbs,
    CmsContent,
    theme,
} from '@tgg/ui';
import {
    GsspWithLoggerParameters,
    getAllPosts,
    getCategoryPagesMap,
    gsspWithLogger,
} from '@tgg/util';

import { applicationIdentifier } from 'apps/commerce/core/constants';
import { LocalConfig } from 'apps/commerce/next.config.types';
import { mapPosts } from 'libs/ui/src/lib/components/BlogPostsList/BlogPostList.helpers';

import { type BlogHomeProperties } from './BlogHomePage.types';

const {
    publicRuntimeConfig: { AMPLIENCE_HUB_ID, APP_BASE_PATH },
    serverRuntimeConfig: {
        AMPLIENCE_FRESH_API_KEY,
        AMPLIENCE_FRESH_API_ON,
        APP_INTERNAL_BASE_URL,
    },
}: LocalConfig = getConfig();

export function BlogHomePage({
    titleBox,
    content,
    posts,
    categories,
    breadcrumbElements,
    heroWithOverlay,
}: BlogHomeProperties) {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    return (
        <>
            {titleBox && <CmsContent schema={titleBox} />}
            {heroWithOverlay && <CmsContent schema={heroWithOverlay} />}
            {isDesktop && breadcrumbElements && (
                <Breadcrumbs elements={breadcrumbElements} />
            )}
            <BlogCategories categories={categories} />
            {content.map(contentItem => (
                <CmsContent key={uuidv4()} schema={contentItem} />
            ))}
            {posts && <BlogPostsList posts={posts} />}
        </>
    );
}

const pageParameters: GsspWithLoggerParameters = {
    applicationIdentifier,
    meta: {
        journeyInfo: {
            journeyName: 'Blog',
            journeyStep: 'BlogHomePage',
        },
    },
};

export const getServerSideProps = gsspWithLogger<BlogHomeProperties>(
    async (_context, injectorParameters) => {
        const { isMobileSession, loggerParameters, logger } =
            injectorParameters;
        const { params } = _context;
        const domain = `${APP_INTERNAL_BASE_URL}${APP_BASE_PATH}`;

        const { titleBox, content } = params as unknown as Omit<
            BlogHomeProperties,
            'posts'
        >;

        const cmsClient = new CmsClient({
            hubName: AMPLIENCE_HUB_ID,
            freshApiKey: AMPLIENCE_FRESH_API_KEY,
            isFreshOn: AMPLIENCE_FRESH_API_ON,
        });

        const getAllPostsPromise = await getAllPosts(cmsClient, {
            'details/hidePostOnBlogHomePage': false,
        });
        const categoryPagesMapPromise = await getCategoryPagesMap(cmsClient);

        const [posts, categoryPagesMap] = await Promise.all([
            getAllPostsPromise,
            categoryPagesMapPromise,
        ]);

        const breadcrumbElements: BreadcrumbsElement[] = [
            {
                label: (titleBox?.text || 'blog') as string,
            },
        ];

        const heroWithOverlay = content?.find(
            item =>
                // eslint-disable-next-line no-underscore-dangle
                item._meta.schema ===
                'https://thegymgroup.com/libs/ui/src/lib/components/HeroWithOverlay/HeroWithOverlay.schema.json',
        );

        if (heroWithOverlay) {
            content.splice(content.indexOf(heroWithOverlay), 1);
        }

        const { data: categoriesList } = await axiosInstance.get(
            `${domain}/api/cms/blog/categories`,
        );

        const categories = categoriesList.reduce(
            (
                accumulator: { name: string; href: string }[],
                category: string,
            ) => {
                const href = categoryPagesMap[category];

                if (category && href) {
                    accumulator.push({
                        name: category,
                        href: `/${href}/`,
                    });
                }
                return accumulator;
            },
            [],
        );

        return {
            props: {
                titleBox,
                categories,
                content,
                posts: mapPosts(posts, categoryPagesMap),
                breadcrumbElements,
                heroWithOverlay: heroWithOverlay ?? null,
            },
        };
    },
    pageParameters,
);
