import { v4 as uuidv4 } from 'uuid';

import { CmsContent } from '@tgg/ui';

import { StandardPageProperties } from './StandardPage.types';

export function StandardPage({
    titleBox,
    content,
    cmsPage,
    gyms,
}: StandardPageProperties) {
    const contextualProperties = {
        Generic: {
            page: cmsPage,
        },
        ButtonStandaloneCms: {
            ctaPosition: 'homepage body',
        },
        FindAGymComponent: {
            gyms,
        },
    };
    return (
        <>
            {titleBox && <CmsContent schema={titleBox} />}
            {content.map((contentItem, index) => (
                <CmsContent
                    /* eslint-disable-next-line no-underscore-dangle */
                    key={uuidv4()}
                    schema={contentItem}
                    contextualProps={contextualProperties}
                />
            ))}
        </>
    );
}
